<template>
  <div class="section-wrapper">
    <b-row>
      <b-col lg="12" sm="12">
        <body-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <template>
                  <div class="text-right">
                    <b-button variant="primary" @click="pdfExport" class="btn btn-sm mb-2">
                      <i class="ri-printer-line"></i> {{ $t('globalTrans.print') }}
                    </b-button>
                  </div>
                  <TeaGardenReportHead :baseUrl="teaGardenServiceBaseUrl" :uri="'/configuration/report-heading/detail'" :orgId="5" :loadingState="false">
                    {{ $t('teaGardenService.bangladesh_tea_board') }}
                  </TeaGardenReportHead>
                  <div class="row mt-3">
                    <div class="col-6">{{$t('teaGardenService.memorial_no')}}: {{ item.registration_no }}</div>
                    <div class="col-6 text-right">{{$t('teaGardenService.date')}}: {{ item.published_date | dateFormat }}</div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-1">
                      <p>{{$t('teaGardenService.subject')}}:</p>
                    </div>
                    <div class="col-11">
                      <b> {{ ($i18n.locale === 'bn') ? item.title_bn : item.title_en }} </b>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-12">
                      <div v-if="$i18n.locale === 'bn'" v-html="item.description_bn">
                      </div>
                      <div v-else v-html="item.description_en">
                      </div>
                    </div>
                  </div>
                  <div class="row mt-5 text-center">
                    <div class="col-4 offset-8">
                      <p>{{$t('teaGardenService.tea_board_deputy_director_planning')}}</p>
                      <p>{{$t('teaGardenService.phone')}}: {{ '031-682347' | numberConvert }}</p>
                      <p>{{$t('teaGardenService.email')}}: btbplanning@gmail.com</p>
                    </div>
                  </div>
                </template>
              </b-col>
            </b-row>
          </template>
        </body-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import TeaGardenReportHead from '@/components/custom/TeaGardenReportHead'
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { circularShow } from '../../api/routes'
export default {
  name: 'Details',
  props: ['id'],
  components: {
    TeaGardenReportHead
  },
  data () {
    return {
      teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
      valid: null,
      loading: false,
      item: {},
      errors: [],
      detailsData: [],
      detailsItemId: ''
    }
  },
  created () {
    const tmp = this.getCircularDetails()
    this.item = tmp
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    }
  },
  methods: {
    getCircularDetails () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async pdfExport () {
      const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 5, app_id: this.id })
      const service = this.$store.state.TeaGardenService.commonObj.masterServiceList.find(item => item.value === 19)
      if (service !== undefined) {
        if (service.office_type_id) {
          params.office_type_id = service.office_type_id
        }
        if (service.office_id) {
          params.office_id = service.office_id
        }
      }
      const result = await RestApi.getPdfData(teaGardenServiceBaseUrl, circularShow + '/' + this.id, params)
      var blob = new Blob([result], {
        type: 'application/pdf'
      })
      var url = window.URL.createObjectURL(blob)
      window.open(url).print()
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    }
  }
}
</script>
